import axios from "axios";
import { uid } from "radash";
import settings from "./settings";

/** ************************
 * # Fetch API
 * # All Units
 ************************ */
const { websiteURL } = settings;
const APIFetchUnits = async () => {
  const response = await axios({
    // headers: { Accept: "application/json", "Access-Control-Allow-Origin": "*" },
    method: "GET",
    url: `${websiteURL}wp-json/north/v2/unit/?rr=${uid(20, "*")}`,
  });
  return response.data;
};

export default APIFetchUnits;
